import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from 'styled-components'
import logoImage from '../images/TWC-logo.png'

const WrapBar = styled.div`
    margin-top:10px;
    height: 25px;
    vertical-align: bottom;
    width: 100%;
    border: 10px solid #96938d;
  background-color:#96938d;
`
const Wrapper = styled.div`
display:flex;
flex-direction:row;
  background-color: #454952;
  justify-content: space-between;
      @media (max-width: 900px) {
  flex-direction:column;
  align-items:center;
  }

`

const LogoBox = styled(Link)`
  margin: 30px 30px;
  height:133px; width:100%;
  max-width: 369px;
   > img { max-width:100%; } 
    // font-family:'Lato';

          @media (max-width: 900px) {
    justify-content: center;
  }
`

const MenuBox = styled.div`
padding:0 30px;
justift-self: center;
align-self: center;
width:800px;
        @media (max-width: 900px) {
    flex-direction:column;
    margin-right: 0;
    width:100%;
    padding: 0 0;
  }

  > .mob_hide { 
    @media (max-width: 600px) {
    display:none;
  }
}
`

const MenuLinks = styled.div`
  font-family: "Advent Pro", sans-serif;
  font-weight:700;
  font-size:16px;
  color: white;
  display:flex;
  justify-content: space-between;
  justify-items:center;
  align-items: center;
  padding: 20px 0;

  > a .mob_hide { 
    @media (max-width: 600px) {
    display:none;
  }
}
          @media (max-width: 600px) {
    flex-wrap:wrap;
    justify-content: space-evenly;

  }



  & > a { 
    text-transform: uppercase;
    text-decoration: none;
    color: white;}
`

const MenuText = styled.div`
  font-family: "Advent Pro", sans-serif;
  text-align:end;
  font-weight:100;
  font-size:24px;
  color:white;
`
const MenuButton = styled(Link)`
  font-family: Roboto;
  font-size:14px;
  background-color:rgb(227, 103, 78);
  border-radius: 25px;
  padding: 10px 30px;
  letter-spacing:1px;
  text-align:center;
      @media (max-width: 600px) {
    display:none;
  }

`



const Header = ({ siteTitle }) => (
  <div>
  <Wrapper>
    <LogoBox to="/">
      <img src={logoImage} height='100%' width='100%' alt='Trevor Wharton Contracts'/>
    </LogoBox>

    <MenuBox>
      <MenuLinks>
        <Link to="/" activeStyle={{ color: "rgb(227, 103, 78)" }}>Home</Link>
        <Link to="/services#about" activeStyle={{ color: "rgb(227, 103, 78)" }}>About</Link>
        <Link to="/services" activeStyle={{ color: "rgb(227, 103, 78)" }}>Services</Link>
        <Link to="/portfolio" activeStyle={{ color: "rgb(227, 103, 78)" }}>Walkthrough</Link>
        <Link to="/contact" activeStyle={{ color: "rgb(227, 103, 78)" }}>Contact</Link>
        <MenuButton to="/contact" className="mob_hide">Free Quote</MenuButton>
      </MenuLinks>
      <MenuText className="mob_hide">The complete flooring contractors</MenuText>
    </MenuBox>

  </Wrapper>
    <WrapBar />
    </div>
)


export default Header
