import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from 'styled-components'
import solutionImage1 from "../images/tailored-contracts.jpg"
import solutionImage2 from "../images/commercial-packages.jpg"
import solutionImage3 from "../images/cleaning-services.png"
import bgImage from "../images/about-us.jpg"

const Wrapper = styled.div`
width: 100%;
min-height: 30vh;
height: auto;
background-color: rgb(242, 245, 248);
display: flex;
flex-direction: column;
align-self: center;
justify-content: space-evenly;
background-attachment: fixed;
position: relative;
     background-image: url(${bgImage});
  background-size:cover;
`
const SolutionTitle = styled.div`
  padding: 20px;
  font-family: "Advent Pro", Arial, Helvetica, sans-serif;
  color: ${props => props.color};
  font-size:2em;
  font-stretch:100%;
  font-weight:900;
  text-align: center;
    align-self:center;
    transition:0.2s;
`

const SolutionSubTitle = styled.div`
  padding-top: 20px;
  font-family: "Advent Pro", Arial, Helvetica, sans-serif;
  color: ${props => props.color};
  text-transform:uppercase;
  font-size:18px;
  font-stretch:100%;
  font-weight:900;
  text-align: center;
    align-self:center;
    transition:0.2s;
`

const Text = styled.div`
  display:flex;
  padding: 20px;
  font-family: Lato;
  color: white;
  font-size:1em;
  font-stretch:100%;
  // font-weight:700;
  text-align: center;
    align-self:center;
`

const SolutionTextBox = styled.div`
  width:100%;
  max-width:1000px;
  margin-top:20px;
     display:flex;
    flex-direction: column;
    align-self:center;
    align-items:center;
    justify-content:center;
    // line-height:4em;
    transition: 0.2s;
    text-align:center;
    padding: 0 50px;
`


const ImageContainer = styled.div`
  overflow:hidden;
  height:7em;
  background-color:red;
 background-image: url(${bgImage});
  background-size:cover;
`

const Overlay = styled.div`
display:flex;
flex-direction: column;
  width:100%;
  height:100%;
      padding: 5%;
  background-color:rgba(227, 103, 78, 0.73);
    padding-bottom:50px;
`

const TopBackground = styled.div`
height: auto;
min-height: 150px;
overflow: visible !important;
`

const TopBox = styled.div`
display: flex;
align-items: center;
background-color: rgba(0, 0, 0, 0.4);
height: auto;
min-height: 115px;
width: 75%;
min-width: 200px;
position: absolute;
top: 50px;
`
const Title = styled.div`
line-height: 1;
font-size: 2em;
font-family: bebas-neue;
color: white;
height: auto;
padding: 20px;
`

const OrangeText = styled.span`
color: rgb(227, 103, 78);
`

const AboutBox = ({ children }) => (
<Wrapper >
  <TopBackground />
  <Overlay id="about">
  <SolutionSubTitle> We're proud to stock fully certified products only</SolutionSubTitle>
  <SolutionTitle color="white"> About Us </SolutionTitle>
  <SolutionTextBox> 
    <Text>We offer high-quality products and good value for money so that you have everything you need to create a warm and cosy atmosphere in your home or office. We also offer a complete delivery and installation service.
  </Text><Text>We are a forward thinking group of flooring professionals who always put customer satisfaction first. We always try to give the best possible advice and service to our customers and we will always take that extra step to ensure that every one of our customers is a happy customer.
</Text><Text>We’re very proud to stock fully certified products only, sourced from trusted British and international manufacturers. This means that we try to supply the best products at the best possible price, without sacrificing the quality of the supply and fitting process. 
  </Text></SolutionTextBox>
  </Overlay>
<TopBox>
  <Title>WE GO ABOVE AND BEYOND, ALL WORK COMES WITH <OrangeText>12 MONTHS</OrangeText> GUARANTEE</Title>
</TopBox>
</Wrapper>
)


export default AboutBox
