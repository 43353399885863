import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from 'styled-components'
import solutionImage1 from "../images/tailored-contracts.jpg"
import solutionImage2 from "../images/commercial-packages.jpg"
import solutionImage3 from "../images/cleaning-services.jpg"

const Wrapper = styled.div`
  width:100%;
      min-height: 30vh;
      height:auto;
      background-color:white;
    display:flex;
    flex-direction: column;
    align-self:center;
    justify-content:space-evenly;

    background-attachment: fixed;
    // line-height:4em;
    transition: 0.2s;
    padding-top:20vh;
    padding-bottom:20vh;
`
const SolutionTitle = styled.div`
  padding: 30px;
  font-family: "Advent Pro", Arial, Helvetica, sans-serif;
  color: ${props => props.color};
  font-size:47px;
  line-height:47px;
  font-stretch:100%;
  font-weight:600;
  text-align: center;
    align-self:center;
    transition:0.2s;
`

const BoxTitle = styled.div`
  padding: 30px;
  font-family: "Advent Pro", Arial, Helvetica, sans-serif;
  color: ${props => props.color};
  font-size:27px;
  font-stretch:100%;
  font-weight:600;
  text-align: center;
    align-self:center;
    transition:0.2s;
`

const SolutionSubTitle = styled.div`
  // padding: 20px;
  font-family: "Advent Pro", Arial, Helvetica, sans-serif;
  color: ${props => props.color};
  &:hover{
  color: #454952; }
  font-size:18px;
  font-stretch:100%;
  font-weight:700;
  text-align: center;
    align-self:center;
    transition:0.2s;
    line-height:1.5;
`

const SolutionText = styled.div`
  padding: 16px;
  font-family: Lato;
  color: #585858;
  font-size:1em;
  font-stretch:100%;
  font-weight:400;
  text-align: center;
    align-self:center;
    line-height:32px;
`

const SolutionWrapper = styled.div`
display:flex;
  flex-direction:row;
  justify-content: space-evenly;
    @media (max-width: 700px) {
    flex-direction:column;
  }
padding: 25px 25px 15px 25px;
`
const SolutionBox = styled.div`
display:flex;
flex-direction: column;
flex: 1 1 0;
margin: 0 10px;
min-height:100%;
overflow:hidden;
  @media (max-width: 700px) {
    width:100%;
    margin: auto;
        margin-bottom: 20px;
        min-height: 700px;

  }
  background-color: #f2f5f8;
  padding: 1em;
  width:30%;
    transition: 0.2s;
`

const SolutionTextBox = styled.div`
margin-top:20px;
  background-color:white;
  width:100%;
  flex-grow: 2;
     display:flex;
    flex-direction: column;
    align-self:center;
    justify-content:flex-start;
    line-height:1.2;
    transition: 1s;
    transition-timing-function: ease;

`

const SolutionImage = styled.img`
  overflow:hidden;
  width:100%;
  height:100%;
  &:hover {
    transform: scale(1.1);
  }
  transition:.5s;

  height:250px;
`

const ImageContainer = styled.div`
  overflow:hidden;
  background-image: url(${props => props.src});
  width:100%;
  height:100%;
  &:hover {
    transform: scale(1.1);
  }
  transition:.5s;

  height:250px;
  background-size: cover;
  min-height:150px;
`

const SolutionBoxes = ({ children }) => (
<Wrapper>
  <SolutionSubTitle> WE ARE A PROFESSIONAL </SolutionSubTitle>
  <SolutionTitle color="rgb(227, 103, 78)"> Full-Service Solution </SolutionTitle>
  <SolutionWrapper>
    <SolutionBox> <ImageContainer src={solutionImage1} />
      <SolutionTextBox>
        <BoxTitle> Tailored Contracts </BoxTitle> 
        <SolutionText>Folllowing your initial enquiry, we will send a qualified person to assess the site to check for site conditions i.e. moisture and type of sub floor, so we can offer a solution to achieve a prepared floor to make the finished product give you the perfect finish.</SolutionText>
      </SolutionTextBox>
    </SolutionBox>
    <SolutionBox> 
      <ImageContainer src={solutionImage2} > </ImageContainer>
      <SolutionTextBox>
        <BoxTitle> Commercial Contracts </BoxTitle> 
        <SolutionText> 
We create quality, durable and attractive floors for demanding applications. Our team of experienced commercial installers can work directly with you or your contractor to create the perfect look for your new build or refurbishment. Our installers have the experience & expertise to uphold our reputation for quality & service, earned over 40 years in the industry. 
      </SolutionText>
      </SolutionTextBox>
    </SolutionBox>
    <SolutionBox> 
      <ImageContainer src={solutionImage3} > </ImageContainer>
      <SolutionTextBox>
        <BoxTitle> Cleaning Services </BoxTitle> 
        <SolutionText> We have the latest in cutting edge technology to offer a service second to none cleaning your hard flooring or carpet wether it be a full store or an office. Our cleaning is run by Matthew who has many years of experience in all aspects of bringing that tired floor back to life.
        </SolutionText>
      </SolutionTextBox>
    </SolutionBox>
  </SolutionWrapper>
</Wrapper>
)


export default SolutionBoxes
