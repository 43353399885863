import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import HeroBanner from "../components/HeroBanner"
import CActionBanner from "../components/CActionBanner"
import ServiceBoxes from "../components/ServiceBoxes"
import SolutionBoxes from "../components/SolutionBoxes"
import ParallaxBanner from "../components/ParallaxBanner"
import AboutBox from "../components/AboutBox"
import AssocBox from "../components/AssocBox"
import ContactBox from "../components/ContactBox"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={['commercial flooring', 'trevor wharton', 'york', 'flooring contractors', 'flooring installation', 'vinyl flooring']} />
    <HeroBanner />
    <CActionBanner> REQUEST A FREE QUOTE </CActionBanner>
    <ServiceBoxes />
    <SolutionBoxes />
    <ParallaxBanner />
    <AssocBox />
    <ContactBox />
  </Layout>
)

export default IndexPage