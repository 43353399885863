import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from 'styled-components'
import bgImage from '../images/pale-wood.jpg'
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import ContactForm from './ContactForm'
import BackgroundImage from 'gatsby-background-image'

const Wrapper = styled.div`
width: 100%;
height: auto;
background-size: cover;
display: flex;
flex-direction: row;
-webkit-box-align: center;
align-items: center;
-webkit-box-pack: center;
justify-content: center;
background-attachment: fixed;
line-height: 4em;
background-position: center bottom;
background-repeat: no-repeat;
border-bottom: 15px solid rgb(227, 103, 78);
    background-image: url(${bgImage});

`
const BannerText = styled.div`
width:75%;
  font-family: "Advent Pro", Arial, Helvetica, sans-serif;
  color: rgb(69, 73, 82);
  font-size:1em;
  line-height:1em;
  text-align: center;
    align-self:center;
    flex-grow:2;
    display:flex;
    align-items:center;


    @media (max-width: 800px) {
    width:95%;  }

`

const Title = styled.div`
font-family: "Advent Pro", Arial, Helvetica, sans-serif;
color: rgb(227, 103, 78);
font-size: 2em;
font-stretch: 100%;
font-weight: 600;
text-align: center;
align-self: center;
`
const TextBox = styled.div`
  display:flex;
  flex-direction:column;
  justify-content: space-evenly;
  align-items:center;
  width:50%;

  padding: 20px 0;

    @media (max-width: 800px) {
    width:95%  }
`

const QuoteButton = styled.div`
  font-family: Roboto;
  font-size:1em;
  letter-spacing:.1em;
  text-transform:uppercase;
  font-weight:700;
  color:white;
  padding:10px;
  background-color:rgb(227, 103, 78);
  border-radius:25px;
  width:75%;
  align-self:center;
  text-align:center;
  line-height:2em;
  &:hover { 
    background-color:rgb(214, 147, 102);
  }
`

const ParallaxBanner = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "pale-wood.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2080) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data =>{
      const imageData = data.placeholderImage.childImageSharp.fluid;
      return (
      <Wrapper id='quote'>

  <TextBox>
  <Title color="rgb(227, 103, 78)"> Request A Free Quote </Title>
    <ContactForm />

  </TextBox>        </Wrapper> 
        )
    }
      }
      />
)

const StyledBackgroundSection = styled(ParallaxBanner)`
padding:30px;
display:flex;
    flex-direction: row;
    align-items:center;
    justify-content:space-evenly;
  width:100%; height:auto; &:after,&:before{
    background-size: cover; 
    background-position: center bottom;
  } background-size: cover;`

export default StyledBackgroundSection

