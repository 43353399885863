import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from 'styled-components'
import vanImage from "../images/quickturn.png"

const Wrapper = styled(Link)`
  width:100%;
      min-height: 10vh;
      background-color:rgb(227, 103, 78);
      &:hover{ 
  background-color: rgb(214, 147, 102); }
    display:flex;
    flex-direction: column;
    align-self:center;
    justify-content:center;
    background-attachment: fixed;
    text-transform:uppercase;
    line-height:4em;
    transition: all 0.2s ease 0s;
        text-decoration: none;
`
const CTAText = styled.div`
display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    font-family: Roboto;
    color: rgb(255, 255, 255);
    font-size: 1em;
    font-stretch: 100%;
    font-weight: 700;
    text-align: center;
    align-self: center;
    letter-spacing: 0.2em;
`

const StyledImg = styled.img`
width: 100px;
height: 100px;
margin-right: 20px;
`
const CActionBanner = ({ children }) => (
<Wrapper to="/#quote" activeStyle={{ color: "rgb(227, 103, 78)" }}>
  <CTAText> <StyledImg src={vanImage} />{children} </CTAText>
</Wrapper>
)


export default CActionBanner
