import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from 'styled-components'
import heroImage from '../images/parallax-bounce.jpg'
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import BackgroundImage from 'gatsby-background-image'

const Wrapper = styled.div`
  width:100%;
      height: 780px;
    background-position: center bottom;
    background-size: cover;
    background-repeat: no-repeat;
    display:flex;
    flex-direction: row;
    align-items:center;
    justify-content:flex-end;

    background-attachment: fixed;
    line-height:1.5em;

    @media (max-width: 800px) {
    height: auto;
  }

`
const BannerText = styled.div`

  font-family: 'lato';
  color: #585858;
  font-size:16px;
  line-height:32px;
  text-align: center;
    align-self:center;
    display:flex;
    align-items:center;
    padding:40px 0;

`

const Title = styled.div`
  font-family: "Advent Pro", Arial, Helvetica, sans-serif;
  color: #454952;
  font-size:47px;
  font-stretch: 100%;
  font-weight:600;
  text-align:center;
  align-self:center;
    border-bottom: 1px solid rgb(227, 103, 78);
    line-height:2;
`
const TextBox = styled.div`
  margin-right:5vw;
  min-width:300px;
  max-width:920px;
  width:45%;
  height: auto;
   border:15px solid #454952;
  background-color:rgb(242, 245, 248);
  display:flex;
  flex-direction:column;
  // justify-content: space-evenly;
  align-items:center;
  padding:30px;

      @media (max-width: 800px) {
    // flex-direction:column;
    margin-right: 0;
    margin: 20px 0;
    width:90%;
    height: auto;
  }
`

const QuoteButton = styled(Link)`
  width:auto;
  font-family: Roboto;
  font-size:18px;
  letter-spacing:.1em;
  text-transform:uppercase;
  font-weight:700;
  color:white;
  padding:10px 20px;
  margin-top:20px;
  background-color:rgb(227, 103, 78);
  border-radius:25px;
  // width:75%;
  align-self:center;
  text-align:center;
  line-height:2em;
  text-decoration: none;
  &:hover { 
    background-color:rgb(214, 147, 102);
  }
`

const SubTitle = styled.div`
    font-family: "Advent Pro", Arial, Helvetica, sans-serif;
  color: #454952;
  font-size:18px;
  font-stretch: 100%;
  font-weight:700;
  // text-align:center;
  // align-self:center;
  line-height:1.5;
  text-transform:uppercase;
  text-align:center;
`

const ParallaxBanner = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "parallax-bounce.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2080) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data =>{
      const imageData = data.placeholderImage.childImageSharp.fluid;
      return (
      <Wrapper>
      <BackgroundImage Tag="section"
      className={className}
                           fluid={imageData}
                           backgroundColor={`#040e18`}
          >
  <TextBox>
    <SubTitle>We're with you from start to finish</SubTitle>
    <Title> Floor preparation experts </Title>
    <BannerText> We offer specialist services in Carpets, Wood Flooring, Vinyl, Safety Floor, Design Service and Free Measuring Services. We are dedicated to suitable and sustainable flooring with proper preparation for long term results. </BannerText>
    <QuoteButton to="/#quote" activeStyle={{ color: "rgb(227, 103, 78)" }}> Get a free quote now </QuoteButton>
  </TextBox>
   </BackgroundImage>
        </Wrapper> 
        )
    }
      }
      />
)

const StyledBackgroundSection = styled(ParallaxBanner)`
display:flex;
    flex-direction: row;
    align-items:center;
    justify-content:flex-end;
  width:100%; height:780px; 

      @media (max-width: 800px) {
    width:100%;
    height: auto;
  }

  &:after,&:before{
    background-attachment: fixed; background-position: center bottom;}     background-position: center bottom; background-attachment: fixed;

    @media (max-width: 800px) {
    justify-content:center;  }`

export default StyledBackgroundSection

