import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from 'styled-components'
import { FaMapMarkerAlt, FaUsers, FaMedal, FaBoxOpen } from 'react-icons/fa';

const Wrapper = styled.div`
  width:100%;
      min-height: 30vh;
      height:auto;
      background-color:rgb(242, 245, 248);
    display:flex;
    flex-direction: column;
    align-self:center;
    justify-content:space-evenly;
    background-attachment: fixed;
    // line-height:4em;
    transition: 0.2s;
    padding-top:50px;
    padding-bottom:50px;
`
const ServiceTitle = styled.div`
  padding: 20px;
  font-family: Roboto;
  color: ${props => props.color};
  font-size:1.5em;
  font-family: "Advent Pro", Arial, Helvetica, sans-serif;
  font-size:em;
  font-stretch:100%;
  font-weight:700;
  text-align: center;
    align-self:center;
    transition:0.2s;
`

const ServiceText = styled.div`
  // padding: 20px;
  font-family: Lato;
  color: rgb(227, 103, 78);
  font-size:1em;
  font-stretch:100%;
  // font-weight:700;
  text-align: center;
    align-self:center;
`

const ServiceIcon = styled.div`
  color: rgb(227, 103, 78);
  align-self:center;
  max-width:3em;

  > svg {
    width:100%;
    height:100%;
  }
  `

const ServiceWrapper = styled.div`
display:flex;
  flex-direction:row;
  flex-wrap:wrap;
`
const ServiceBox = styled.div`
  @media (max-width: 700px) {
    width:50%;
  }
  padding:30px;
  width:25%;
  height:50%;
     display:flex;
    flex-direction: column;
    justify-content:center;
    // line-height:4em;
    transition: 0.2s;
`


const ServiceBoxes = ({ children }) => (
<Wrapper>
  <ServiceTitle color="rgb(227, 103, 78)"> OUR GUARANTEE </ServiceTitle>
  <ServiceWrapper>
    <ServiceBox> 
      <ServiceIcon> <FaMapMarkerAlt /> </ServiceIcon>
      <ServiceTitle> ANY LOCATION </ServiceTitle> 
      <ServiceText> As a company we carry out work in all corners of the UK and Europe. We have carried out works as far afield as Mongolia.</ServiceText>
    </ServiceBox>
        <ServiceBox> 
      <ServiceIcon> <FaUsers /> </ServiceIcon>
      <ServiceTitle> A LARGE TEAM </ServiceTitle> 
      <ServiceText> Our fitting teams are based all around the UK. All fully insured and have all the necessary qualifications and certifications.  </ServiceText>
    </ServiceBox>
        <ServiceBox> 
      <ServiceIcon> <FaMedal /> </ServiceIcon>
      <ServiceTitle> EXPERIENCED </ServiceTitle> 
      <ServiceText> Our teams work in all types of situations from large contracts to nursing homes where they are considerate to the residents and the staff.</ServiceText>
    </ServiceBox>
        <ServiceBox> 
      <ServiceIcon> <FaBoxOpen /> </ServiceIcon>
      <ServiceTitle>AFTER CARE </ServiceTitle> 
      <ServiceText>We guarantee all our installations for 12 months, but after that time we are still available to help do anything we can if an issue may arise. Our office is always open for advice.</ServiceText>
    </ServiceBox>
  </ServiceWrapper>
</Wrapper>
)


export default ServiceBoxes
