import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from 'styled-components'
import heroImage from '../images/go-bounce.jpg'
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import BackgroundImage from 'gatsby-background-image'

const Wrapper = styled.div`
  width:100%;
  // background-image: url(${heroImage});
  background-size:cover;
      height: 60vh;
    background-position: center bottom;
    background-size: cover;
    background-repeat: no-repeat;
    display:flex;
    flex-direction: column;
    align-self:center;
    justify-content:center;
    background-attachment: fixed;
    text-transform:uppercase;
    line-height:4em;

`
const HeroText = styled.div`
  font-family: "Advent Pro", Arial, Helvetica, sans-serif;
  color: #ffffff;
  font-size:1.5em;
  font-stretch:100%;
  font-weight:700;
  text-align: center;
    align-self:center;

`

const HeroTitle = styled.div`
  font-family: "Advent Pro", Arial, Helvetica, sans-serif;
  color: rgb(255,168,150);
  font-size:3em;
  font-stretch: 100%;
  font-weight:700;
  text-align:center;
      align-self:center;
`
const TextBox = styled.div`
width:100%;
// height:100%;
  position: absolute;
  left: 0;
  // top: 50%;
  width: 100%;
  text-align: center;
  // transform: translate(-50%, -50%);

  > .mob_hide { 
    @media only screen and (max-width: 600px) {
      display:none;

    }
  }
`

const HeroBanner = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "go-bounce.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data =>{
      const imageData = data.placeholderImage.childImageSharp.fluid;
      return (
      <Wrapper>
      <BackgroundImage Tag="section"
      className={className}
                           fluid={imageData}
                           backgroundColor={`#040e18`}
          >
          <div></div>
        </BackgroundImage>
                <TextBox>
        <HeroText className="mob_hide">Flooring solutions without the hassle</HeroText>
        <HeroTitle>We make it simple</HeroTitle>
        <HeroText>Professional service with quality and customer satisfcation</HeroText></TextBox>
        </Wrapper> 
        )
    }
      }
      />
)

const StyledBackgroundSection = styled(HeroBanner)`
  width:100%; height:100%; 

  background-position: bottom; background-attachment: fixed;
  // background-size:cover;

  &:after,&:before{ 
    background-attachment: fixed;
    background-size:contain;
    // background-repeat:no-repeat;
    background-position: center bottom;
  } 
`

export default StyledBackgroundSection