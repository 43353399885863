import React from 'react'
import { render } from 'react-dom'
import { Form, Field } from 'react-final-form'
import styled from 'styled-components'

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

const onSubmit = async values => {
  await sleep(300)
  window.alert("Thanks for your submission!");
}

const Wrapper = styled.div`
  width:100%;
`

const Button = styled.div`
  font-family: Roboto;
  font-size:14px;
  letter-spacing:2px;
  text-transform:uppercase;
  font-weight:900;
  color:white;
  background-color:rgb(227, 103, 78);
  border-radius:25px;
  width:100px;
  text-align:center;
  line-height:27px;
  &:hover { 
    background-color:rgb(214, 147, 102);
  }
`

const Label = styled.label`
  font-family:lato;
  font-size:16px;
`

const ContactWrapper = styled.div`
  display:flex;
  flex-direction:column;
  margin-top:20px;
  & > input {
  border: 1px solid #dddbdb;
  border-radius:100px;
  padding: 0 20px;
  }
& textarea {
  border: 1px solid #dddbdb;
  border-radius:30px;
  padding: 0 20px;
  }
& buttons {
  margin-top:20px;
}
`

const ContactForm = () => (
  <Wrapper>
    <Form
      onSubmit={onSubmit}
      initialValues={{ }}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit}>
          <ContactWrapper>
            <Label>Your Name: (Required)</Label>
            <Field
              name="firstName"
              component="input"
              type="text"
            />
          </ContactWrapper>
          <ContactWrapper>
            <Label>Your Email: (Required)</Label>
            <Field
              name="email"
              component="input"
              type="email"
            />
          </ContactWrapper>
          <ContactWrapper>
            <Label>Telephone Number:</Label>
            <Field name="employed" component="input" type="text" />
          </ContactWrapper>
          <ContactWrapper>
            <Label>Location</Label>
            <Field name="notes" component="input" type="text" />
          </ContactWrapper>
          <ContactWrapper>
            <Label>Further Details:</Label>
            <Field name="notes" component="textarea" />
          </ContactWrapper>
          <ContactWrapper className="buttons">
            <Button type="submit" disabled={submitting || pristine}>
              Send
            </Button>
          </ContactWrapper>
          {/*<pre>{JSON.stringify(values, 0, 2)}</pre>*/}
        </form>
      )}
    />
    </Wrapper>
)

export default ContactForm