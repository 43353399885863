import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from 'styled-components'
import solutionImage1 from "../images/tailored-contracts.jpg"
import solutionImage2 from "../images/commercial-packages.jpg"
import solutionImage3 from "../images/cleaning-services.png"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Wrapper = styled.div`
  width:100%;
      min-height: 30vh;
      height:auto;
    background-color: #f2f5f8;
    display:flex;
    flex-direction: column;
    align-self:center;
    justify-content:space-evenly;
    padding: 5em;
    background-attachment: fixed;
    // line-height:4em;
    transition: 0.2s;
    padding-top:50px;
    padding-bottom:50px;

       @media (max-width: 600px) {
        padding: 0px 0px;
    }
`
const Title = styled.div`
  // padding: 20px;
  font-family: "Advent Pro", Arial, Helvetica, sans-serif;
  color: rgb(227,103,78);
  font-size:27px;
  font-stretch:100%;
  font-weight:700;
  text-align: center;
    align-self:center;
    transition:0.2s;
    text-transform:uppercase;


`
const SolutionImg = styled.a`
  // overflow:hidden;
  width:100%;
  height:100%;
  max-width:200px;
  transition:3s;

  flex-shrink: 0;

   @media (max-width: 600px) {
      padding:0 2em;
    }
`

const ImageContainer = styled.div`
  overflow:hidden;
  overflow-x: auto;

  /* make it smooth on iOS */
  -webkit-overflow-scrolling: touch;
  display:flex;
  flex-direction:row;
    @media (min-width: 600px) {

        justify-content: space-around;
  flex-wrap:wrap;
    }
    @media (max-width: 600px) {
    flex-wrap:nowrap;
    scroll-snap-points-x: repeat(200px);
  scroll-snap-type: mandatory;
  }
`

const AssocBox = () => {

  const data = useStaticQuery(graphql`
    fragment servicesImage on File {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    query {
      image1: file(relativePath: { eq: "assoc1.png" }) {
        ...servicesImage
      }
      image2: file(relativePath: { eq: "assoc2.png" }) {
        ...servicesImage
      }
      image3: file(relativePath: { eq: "assoc3.png" }) {
        ...servicesImage
      }
      image4: file(relativePath: { eq: "assoc4.png" }) {
        ...servicesImage
      }
      image5: file(relativePath: { eq: "assoc5.png" }) {
        ...servicesImage
      }
    }
  `)

  return (
      <Wrapper>
    <Title> We Specialise In: </Title>
    <ImageContainer>
    <SolutionImg href="https://www.moduleo.co.uk/visualiser" target="_blank">
      <Img fluid={data.image1.childImageSharp.fluid} />
    </SolutionImg>
    
    <SolutionImg href="https://www.karndean.com/en/commercial-flooring" target="_blank">
      <Img fluid={data.image2.childImageSharp.fluid} />
    </SolutionImg>
    
    <SolutionImg href="https://www.ivc-commercial.com/en" target="_blank">
      <Img fluid={data.image5.childImageSharp.fluid} />
    </SolutionImg>
    
        <SolutionImg href="https://www.ivcgroup.com/en/" target="_blank">
      <Img fluid={data.image3.childImageSharp.fluid} />
    </SolutionImg>

    <SolutionImg href="https://www.polyflor.com/jh/products.nsf/products" target="_blank">
      <Img fluid={data.image4.childImageSharp.fluid} />
    </SolutionImg>
    
    </ImageContainer>
    </Wrapper>
  )}

  export default AssocBox
