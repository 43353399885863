import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from 'styled-components'
import logoImage from '../images/TWC-logo.png'
import f1Image from '../images/footer/footer1.png'
import f2Image from '../images/footer/footer2.png'

const WrapBar = styled.div`
  height:90px;
    width: 100%;
  background-color:#454951;
    display:flex;
  text-align:center;
  justify-content: center;
  flex-direction:row;
  align-items:center;
`
const Wrapper = styled.div`
justify-items: center;
background-color: white;
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: space-around;
padding: 1em 5%;
border-top: 15px solid rgb(227, 103, 78);
gap: 2em 2em;

      @media (max-width: 800px) {
    grid-template-columns: 1fr;  }

`

const MenuBox = styled.div`
justify-self: center;
align-self: center;
  grid-column: 4;
`

const MenuLinks = styled.div`
  font-family:'Lato';
`

const MenuText = styled.div`
  font-family:'Lato';
`

const TextBox = styled.div`
height: auto;
width: auto;
display: flex;
flex-direction: column;
`

const ImageBox = styled.div`
height: auto;
width: auto;
display: flex;
text-align: center;
-webkit-box-pack: center;
justify-content: center;
flex-direction: row;
-webkit-box-align: center;
align-items: center;
  & > img { width: 9em;}
`

const Title = styled.div`
    font-family:'Roboto';
    font-weight:900;
    padding: 1em 0;
  
`

const SubTitle = styled.div`
  font-family:'Lato';

`

const Text = styled.div`
  font-family:'Lato';
  display:grid; 
  text-align: left;

`

const TextFooter = styled.div`
  color:white;
    font-family:'Lato';
    font-weight:400;
    font-size:0.75em;

    & > a { text-decoration: none; color:white; }
`

const Footer = ({ siteTitle }) => (
  <footer>
  <Wrapper>
<link rel="stylesheet" href="https://use.typekit.net/wgw1ocl.css" />

  <TextBox> 
    <Title> CONTACT </Title>
    <Text>For any enquiries please feel free to contact us on:
      <SubTitle>Phone:</SubTitle> 01904 849450
      <SubTitle>Email:</SubTitle> trevor@twcarpets.co.uk
      </Text>
      </TextBox>

      <TextBox>
    <Title> ADDRESS </Title>
    <Text><span>
    Unit 5, 35 Hospital Fields Road,
      </span>
Fulford Business Park,
Fulford, York
YO10 4DZ
      </Text>
  </TextBox>
<ImageBox>
 <img src={f1Image} alt='Trevor Wharton Contracts'/>
  <img src={f2Image} alt='Trevor Wharton Contracts'/>
</ImageBox>
  </Wrapper>
  <WrapBar> <TextFooter> © Copyright TWCarpets 2019   |   Designed by <a href="http://practicallycreative.co.uk"> PracticallyCreative </a>  | Powered by <a href="https://gatsbyjs.og"> GatsbyJS</a> | All Rights Reserved   </TextFooter> </WrapBar>
  </footer>
)


export default Footer
